import styled from '@emotion/styled';
import {
  BANNER_OPACITY,
  BODY_COLOR_OPACITY,
  TAB_BAR_OPACITY,
  hexToRgba,
} from '../../../utils/common';

export const MiddleBodyVideoBackground = styled.div`
  background: ${(props) =>
    !props?.propsBackground?.content[0]?.url && `none, ${props?.themeBackground}`};
  background-size: cover;
  background-position: center;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0; /* Set the z-index behind other content */
  }
  /* Add a linear gradient overlay */
  &::after {
    content: '';
    background: ${(props) => props?.themeBackground};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: ${BANNER_OPACITY};
  }
  @media screen and (min-width: 768px) {
    & > video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; /* Scale the video while maintaining its aspect ratio */
    }
  }
  @media screen and (max-width: 767px) {
    & > video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; /* Scale the video while maintaining its aspect ratio */
    }
  }
  & > video {
    position: absolute;
    height: 100%;
  }
`;

export const MiddleBodyParent = styled.div`
  &.bg_type_image {
    background: ${(props) =>
      props?.propsBackground?.content[0]?.url
        ? `url(${props?.propsBackground?.content[0]?.url})`
        : `none, ${props?.themeBackground}`};
    background-size: cover;
    background-position: center;
  }

  &.bg_type_solid {
    background: ${(props) => props?.themeBackground};
  }
`;
export const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    padding: 4rem;
    gap: 4rem;
  }
  @media screen and (max-width: 767px) {
    padding: 2rem 0rem;
    gap: 2.5rem;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  @media screen and (min-width: 768px) {
    gap: 4rem;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem;
  }
`;
export const TopLeftSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  & > h1 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    color: ${(props) => props?.textStyles?.heading?.color};
    font-family: ${(props) => props?.textStyles?.heading?.fontFamily || 'Arial'};
    text-align: ${(props) => props?.textStyles?.heading?.textAlign || 'left'};
  }
  & > p {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    font-style: normal;
    color: ${(props) => props?.textStyles?.body?.color};
    text-align: ${(props) => props?.textStyles?.body?.textAlign || 'left'};
    font-family: ${(props) => props?.textStyles?.body?.fontFamily || 'Arial'};
    opacity: ${BODY_COLOR_OPACITY};
    margin: 0;
  }
  @media screen and (min-width: 768px) {
    & > h1 {
      font-size: ${(props) => props?.textStyles?.heading?.fontSize || '3.25rem'};
      font-weight: ${(props) => props?.textStyles?.heading?.fontWeight || '500'};
      line-height: 3.875rem;
      align-self: flex-start;
      width: 100%;
    }
    & > p {
      font-size: ${(props) => props?.textStyles?.body?.fontSize || '1.25rem'};
      font-weight: ${(props) => props?.textStyles?.body?.fontWeight || '400'};
      line-height: 1.75rem;
      align-self: flex-start;
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    & > h1 {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: normal;
      width: 100%;
      align-self: center;
    }
    & > p {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: normal;
      width: 100%;
    }
  }
`;
export const TopRightSection = styled.div`
  /* min-width: max-content; */
  display: flex;
  flex-wrap: wrap;
  @media screen and (min-width: 768px) {
    align-self: center;
  }
  @media screen and (max-width: 767px) {
    align-self: flex-start;
    width: 100%;
  }
`;
export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  flex-wrap: wrap;
  @media screen and (max-width: 767px) {
    justify-content: center;
    flex-direction: column;
    width: 100%;
    button {
      width: 100%;
    }
  }
`;

export const NavContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  border-bottom: ${(props) =>
    props.isNavItem
      ? `1px solid ${
          hexToRgba(`${props?.textStyles?.nav?.color}`, TAB_BAR_OPACITY) || '#000'
        }`
      : '0px'};
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  & div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  & div > .nav-link-text {
    flex: 0;
    width: 100%;
    padding: 0.875rem 1rem;
    min-width: fit-content;
    color: ${(props) => props?.textStyles?.nav?.color};
    font-family: ${(props) => props?.textStyles?.body?.fontFamily};
    font-weight: ${(props) => props?.textStyles?.body?.fontWeight};
    position: relative;
    cursor: pointer;
    overflow-y: hidden;
  }
  & .nav-link-text > .bar {
    position: absolute;
    border: 2.5px solid ${(props) => props?.textStyles?.nav?.color};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    bottom: -2px;
    right: 0;
    width: 100%;
  }
  & .nav-link-text > .bar.hidden {
    display: none;
  }
  & .nav-link-text > .bar.active {
    display: block;
  }
  @media screen and (max-width: 767px) {
    & div {
      justify-content: flex-start;
    }
  }
`;
export const NavContent = styled.div`
  & .modern_theme_middlebody3 {
    padding: 0 1rem !important;
    background: transparent !important;
  }
  & .modern_theme_middlebody3 > div {
    align-self: center;
  }
  & .modern_theme_middlebody3 .heading {
    font-size: 2.5rem !important;
  }
  & .modern_theme_middlebody3 > div > div:first-child {
    padding: 0rem !important;
  }
  @media screen and (min-width: 768px) {
    & .modern_theme_middlebody3 > div {
      gap: 2.5rem !important;
    }
    & .modern_theme_middlebody3 > div > div:first-child {
      gap: 1.5rem !important;
    }
  }
  @media screen and (max-width: 767px) {
    & .modern_theme_middlebody3 > div {
      gap: 1rem !important;
    }
    & .modern_theme_middlebody3 > div > div:first-child {
      gap: 1rem !important;
    }
  }
`;
